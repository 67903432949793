import { Auth } from "aws-amplify";
import moment from "moment";
import Head from "next/head";
import { createContext, useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { UserBuild } from "../pages/builds";
import { Contact } from "./ContactButton";
import Navbar from "./Navbar";
import { v4 as uuidv4 } from "uuid";
import Script from "next/script";

interface LayoutProps {
  children: JSX.Element;
}
export interface CurrentBuild {
  model: {
    name?: string;
    uuid?: string;
    model_id?: number;
    slug?: string;
    price?: number;
    img?: string;
    pinned?: boolean;
    modelType?: string;
    extPalette?: {
      paletteName: string;
      palettePrice: number;
    };
    garageColour?: string | null;
    intPalette?: {
      paletteName: string;
      palettePrice: number;
    };
    base_model?: number;
    zip?: string;
    deliveryCost?: number;
    bathroom?: {
      faucet?: number | null;
      upgrade?: {
        name: string;
        price: number;
      } | null;
    };
    appliances?: {
      packageName: string;
      price: number;
    } | null;
    kitchenFixtures?: {
      kitchenFaucet: string | null;
      kitchenSink: string;
    };
    laundryFixtures?: {
      laundryFaucet: string | null;
      laundrySink: string | null;
    };
    stairs?: {
      stair: {
        name: string;
        price: number;
      };
      railing: {
        name: string;
        price: number;
      };
    };
    fireplace?: {
      name: string;
      price: number;
    };
    diningPendant?: string;
    islandPendant?: string;
    dveleIq?: boolean;
    inquired?: boolean;
    saved?: boolean;
    buildTotal?: number;
    ip?: string;
  };
}

interface ModelContextType {
  currentBuild: CurrentBuild;
  setCurrentBuild: React.Dispatch<React.SetStateAction<CurrentBuild>>;
}

export const ModelContext = createContext<ModelContextType>({
  currentBuild: {
    model: {
      slug: "",
      modelType: "Advance",
    },
  },
  setCurrentBuild: () => {},
  //savedBuilds: [{}],
});

export const UserContext = createContext("");
export const LoginContext = createContext(null);

function Layout({ children }: LayoutProps) {
  const [currentBuild, setCurrentBuild] = useState<CurrentBuild>(
    typeof window !== "undefined"
      ? JSON.parse(
          localStorage.getItem("currentBuild") ||
            `{
    "model": { "slug": "" }
  }`
        )
      : {
          model: { slug: "" },
        }
  );

  const value = { currentBuild, setCurrentBuild };

  // * initial build creation, saves to db regardless of user auth
  const checkForAuth = async (Build: CurrentBuild) => {
    try {
      const session = await Auth.currentSession();
      if (session) {
        // * user is logged in, create a new build with user info
        const newBuild: UserBuild = {
          ip_address: currentBuild.model?.ip,
          model_id: "1",
          build_name: currentBuild.model?.name,
          created_at: moment().format("YYYY-MM-DD  HH:mm:ss.000"),
          updated_at: moment().format("YYYY-MM-DD  HH:mm:ss.000"),
          build_data: JSON.stringify(currentBuild) as unknown as CurrentBuild,
          saved: false,
          deleted: false,
          uuid: currentBuild.model?.uuid,
        };

        const headers: HeadersInit = {
          "Content-Type": "application/json",
          "x-api-key": process.env.NEXT_PUBLIC_AWS_API_KEY!,
        };
        const build = await fetch(
          process.env.NEXT_PUBLIC_AWS_BASE_URL + "new",
          {
            headers,
            method: "POST",
            body: JSON.stringify(newBuild),
          }
        );
        const result = await build.json();
      }
    } catch (err) {
      // * User is not logged in, create a build without any user info
      const newBuild: UserBuild = {
        ip_address: currentBuild.model?.ip,
        model_id: "1",
        build_name: Build.model?.name,
        created_at: moment().format("YYYY-MM-DD  HH:mm:ss.000"),
        updated_at: moment().format("YYYY-MM-DD  HH:mm:ss.000"),
        build_data: JSON.stringify(currentBuild) as unknown as CurrentBuild,
        saved: false,
        deleted: false,
        uuid: currentBuild.model?.uuid,
      };

      const headers: HeadersInit = {
        "Content-Type": "application/json",
        "x-api-key": process.env.NEXT_PUBLIC_AWS_API_KEY!,
      };
      const build = await fetch(process.env.NEXT_PUBLIC_AWS_BASE_URL + "new", {
        headers,
        method: "POST",
        body: JSON.stringify(newBuild),
      });
      const result = await build.json();
    }
  };

  // * Update build when currentBuild changes regardless of user auth
  const updateBuild = async (build: UserBuild) => {
    // * Check if user is logged in
    try {
      const session = await Auth.currentSession();
      if (session) {
        const headers: HeadersInit = {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " +
            (await Auth.currentSession()).getIdToken().getJwtToken(),
        };
        const body = {
          build: build,
          action: "update",
        };
        const res = await fetch(
          process.env.NEXT_PUBLIC_AWS_BASE_URL + "builds",
          {
            headers,
            method: "PUT",
            body: JSON.stringify(body),
          }
        );
      }
    } catch (err) {
      // * User is not logged in
      const headers: HeadersInit = {
        "Content-Type": "application/json",
        "x-api-key": process.env.NEXT_PUBLIC_AWS_API_KEY!,
      };
      const body = {
        build: build,
        action: "update",
      };
      const res = await fetch(process.env.NEXT_PUBLIC_AWS_BASE_URL + "new", {
        headers,
        method: "PUT",
        body: JSON.stringify(body),
      });
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      localStorage.setItem("currentBuild", JSON.stringify(currentBuild));

      if (currentBuild.model.saved == true) {
        // * Save changes to DB
        const newBuild: UserBuild = {
          updated_at: moment().format("YYYY-MM-DD  HH:mm:ss.000"),
          build_data: JSON.stringify(currentBuild) as unknown as CurrentBuild,
          saved: currentBuild.model?.pinned,
          deleted: false,
          uuid: currentBuild.model?.uuid,
          build_name: currentBuild.model?.name,
        };
        updateBuild(newBuild);
      } else {
        // * There's no build in the DB yet, so create one
        if (currentBuild.model.name != undefined) {
          currentBuild.model.saved = true;
          checkForAuth(currentBuild);
        }
      }
    }
  }, [currentBuild]);

  const [userUUID, setUserUUID] = useState("");
  useEffect(() => {
    const storedUUID = localStorage.getItem("userUUID");
    if (!storedUUID) {
      const newUUID = uuidv4();
      localStorage.setItem("userUUID", newUUID);
      setUserUUID(newUUID);
    } else {
      setUserUUID(storedUUID);
    }
  }, []);

  const cognitoUserQueryKey = useMemo(() => ["cognito", "user"], []);
  const queryClient = useQueryClient();
  const user = queryClient.getQueryData(cognitoUserQueryKey) as any;
  const [loggedInUser, setLoggedInUser] = useState(null);
  useEffect(() => {
    if (user) {
      setLoggedInUser(user.username);
      localStorage.setItem("likedModels", "[]");
    }
  }, [user]);

  return (
    <>
      <Head>
        <title>Dvele Design Studio</title>
        <meta name="description" content="Generated by create next app" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
        {process.env.NEXT_PUBLIC_ENVIRONMENT == "production" && (
          <Script
            id="hs-script-loader"
            src="//js.hs-scripts.com/2632612.js"
            onLoad={() => console.log("Hubspot Script Loaded")}
            onError={() => console.log("Error Loading Hubspot")}
          />
        )}
      </Head>
      <main className="bg-base relative">
        <UserContext.Provider value={userUUID}>
          <LoginContext.Provider value={loggedInUser}>
            <ModelContext.Provider value={value}>
              {/* <Navbar />
              <Contact /> */}
              {children}
            </ModelContext.Provider>
          </LoginContext.Provider>
        </UserContext.Provider>
      </main>
    </>
  );
}

export default Layout;
